.filter .sd-body.sd-body--static {
  padding: 0;
  margin-left: 0;
}

.filter .sd-page {
  padding: 0;
}

.filter .Mui-expanded {
  margin: 10px 0px 0px !important;
}

.filter .MuiTabPanel-root{
  padding: 0px 15px;
}

.filter .MuiMenuItem-root {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.filter .sv-string-viewer--multiline {
  display: none;
}

.filterSelect {
  margin: 15px 0px;
  min-width:300px;
}

